import { Product } from "../../shared/types";

interface CheckListItemProps {
    product: Product;
    toggleProductHandle: (_id: string) => void;
}

export function CheckListItem({ product, toggleProductHandle }: CheckListItemProps) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
            <span>{product.name}</span>
            <input
                type="checkbox"
                checked={product.available}
                onChange={() => toggleProductHandle(product._id)}
            />
        </div>
    );
}