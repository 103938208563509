import React, {useEffect} from 'react';
import './App.css';
import {CheckList} from './components/checklist';
import {AwesomeButton} from "react-awesome-button";

import 'react-awesome-button/dist/styles.css';
import styles from "react-awesome-button/src/styles/themes/theme-blue";

function App() {
  useEffect(() => {
    const sendDataToServer = async () => {
      if (navigator.onLine) {
        console.log('ONLINE!!!');
        /*try {
          const data = localStorage.getItem('offlineData');
          if (data) {
            await fetch('https://your-server.com/api', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: data,
            });
            localStorage.removeItem('offlineData');
          }
        } catch (error) {
          console.error('Ошибка при отправке данных:', error);
        }*/
      }
    };

    window.addEventListener('online', sendDataToServer);
    return () => window.removeEventListener('online', sendDataToServer);
  }, []);

  return (
    <div className="App">
      <h1>Список продуктов</h1>

      <CheckList />

      <AwesomeButton type="primary" cssModule={styles}>Новый продукт</AwesomeButton>
    </div>
  );
}

export default App;
