import {useEffect, useState} from "react";
import {Product} from "../../shared/types";
import { CheckListItem } from "../checklist-item";
import { productApi } from "../../shared/api/products";

export const CheckList = () => {
    const [items, setItems] = useState<Product[]>([]);

    const toggleProduct = (_id: string) => {
        setItems(items.map(item =>
            item._id === _id
                ? { ...item, available: !item.available }
                : item
        ));
    };

    // Функция для загрузки данных из API
    const fetchItems = async () => {
        try {
            const products = await productApi.getProducts();
            setItems(products);
        } catch (error) {
            console.error('Ошибка при загрузке данных:', error);
        }
    };

    // Загрузка данных при первом рендере и через каждые 30 секунд
    useEffect(() => {
        fetchItems();
        const interval = setInterval(fetchItems, 30000); // Обновление каждые 30 секунд
        return () => clearInterval(interval); // Очистка интервала при размонтировании
    }, []);

    return (
        <div>
            {items.map(product => (
                <CheckListItem
                    key={product._id}
                    product={product}
                    toggleProductHandle={toggleProduct}
                />
            ))}
        </div>
    );
};